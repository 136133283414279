import { useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../base/App.controller';
import magnifiyingGlass from '../../../../images/magnifying-glass.svg';
import DialogWrapper from '../../wrappers/DialogWrapper';
import LinkWrapper from '../../wrappers/LinkWrapper';
import context from 'components/tracer-provider/TracerContext';
import _get from 'lodash/get';
import axios from 'axios';
import buildLightstepParams from 'helpers/build-lightstep-params/buildLightstepParams';
import buildLightstepErrorParams from 'helpers/build-lightstep-error-params/buildLightstepErrorParams';
import { IGenericErrorModalProps } from '../types';
import { Itranslation } from 'types';
import { Span } from 'opentracing';

const { publicRuntimeConfig } = require('next/config').default();

const GenericErrorModal: React.FC<IGenericErrorModalProps> = ({
    AppStore,
    Store,
    onClick = async () => Promise.resolve(),
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'common');
    const { tracer, initSpan, sessionId } = useContext(context);

    useEffect(() => {
        if (tracer && Store.isOpen) {
            initSpan?.setTag('error', true);
            const errorMessage = Store.serverMessage ? Store.errorMessage : t(Store.errorMessage);
            const errorParams = buildLightstepErrorParams(`${errorMessage}`);
            const errorSpan = tracer.startSpan(`ERROR ${errorMessage}`, {
                childOf: initSpan as Span,
                tags: {
                    error: true,
                    errorMessage,
                    errorPriority: _get(errorParams, 'errorPriority', 'medium'),
                },
            });
            errorSpan.finish();
            initSpan?.finish();

            const userParams = buildLightstepParams(AppStore, errorParams);
            axios.post(`/query-lightstep?sessionId=${sessionId}${userParams}`);
        }
    }, [Store.isOpen]);

    const _onClose = async () => {
        Store.closeModal();
        await onClick();
    };

    return (
        <DialogWrapper open={Store.isOpen} onClose={_onClose} hideDivider className="ErrorModal">
            <img className="magnifiyingGlass" src={magnifiyingGlass} />
            <h2>{t('whoops')}!</h2>
            <div className={'errorMessage'}>
                {Store.serverMessage
                    ? Store.errorMessage
                    : t(Store.errorMessage, { tppName: AppStore.scope })}
                {Store.showServiceTicket && (
                    <>
                        <LinkWrapper
                            className={'link'}
                            target="_blank"
                            rel="noopener"
                            href={publicRuntimeConfig.externalLinks.support.default}>
                            {t('errors.ticket')}
                        </LinkWrapper>
                        .
                    </>
                )}
            </div>
            <br />
            <div style={{ fontSize: 12 }}>
                Session Id: {_get(AppStore, 'member.memberId') || sessionId}
            </div>
            {Store.tokenTraceId && (
                <div className="tokenTraceId" style={{ fontSize: 12 }}>
                    token-trace-id: {Store.tokenTraceId}
                </div>
            )}
            <br />
            <Button variant="contained" color="primary" onClick={_onClose}>
                {t('close')}
            </Button>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(GenericErrorModal));
