module.exports = {
    provinces: {
        FR: [
            'Auvergne-Rhône-Alpes',
            'Bourgogne-Franche-Comté',
            'Bretagne',
            'Centre-Val de Loire',
            'Corse',
            'Grand Est',
            'Hauts-de-France',
            'Île-de-France',
            'Normandie',
            'Nouvelle-Aquitaine',
            'Occitanie',
            'Pays de la Loire',
            "Provence-Alpes-Côte d'Azur",
        ],
        HU: [
            'Bács-Kiskun megye',
            'Baranya megye',
            'Békés megye',
            'Borsod-Abaúj-Zemplén megye',
            'Csongrád-Csanád megye',
            'Fejér megye',
            'Győr-Moson-Sopron megye',
            'Hajdú-Bihar megye',
            'Heves megye',
            'Jász-Nagykun-Szolnok megye',
            'Komárom-Esztergom megye',
            'Nógrád megye',
            'Pest megye',
            'Somogy megye',
            'Szabolcs-Szatmár-Bereg megye',
            'Tolna megye',
            'Vas megye',
            'Veszprém megye',
            'Zala megye',
        ],
    },
};
