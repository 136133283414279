import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import ModalStore from 'components/common/modals/ModalStore';
import { TOKEN } from 'constants/banks';
import {
    AIS,
    PIS,
    CBPII,
    REDEEM_TOKEN,
    CUSTOMIZE_FLOW,
    HIDE_CONSENT,
    PARTNER,
    ACT_ON_BEHALF_OF,
    REQUEST_REFUND_ACCOUNT,
    PERFORM_KYC,
    REFUND,
    STANDING_ORDER,
    FUTURE_DATED,
    SINGLE_IMMEDIATE_PAYMENT,
    VARIABLE_RECURRING_PAYMENT,
    BULK_TRANSFER,
    DO_NOT_ALLOW_API_KEY_AUTHENTICATION,
} from 'constants/memberPermissions';
import React from 'react';
import { AppStore } from 'AppStore';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class UpdatePermissionsModalStore extends ModalStore {
    constructor(public rootStore: any, public isTppManager = false) {
        super();
        this.rootStore = rootStore;
        this.isTppManager = isTppManager;
    }
    perPage = 1;
    page = 1;

    @observable
    clientType = '';
    @observable
    memberType = '';
    @observable
    disable = true;
    @observable
    clientId = '';
    @observable
    isAdminTppResellerFlow = false;
    @observable
    permissionsList = [
        AIS,
        CBPII,
        PARTNER,
        REDEEM_TOKEN,
        CUSTOMIZE_FLOW,
        HIDE_CONSENT,
        ACT_ON_BEHALF_OF,
        REQUEST_REFUND_ACCOUNT,
        PERFORM_KYC,
        REFUND,
        STANDING_ORDER,
        FUTURE_DATED,
        SINGLE_IMMEDIATE_PAYMENT,
        VARIABLE_RECURRING_PAYMENT,
        DO_NOT_ALLOW_API_KEY_AUTHENTICATION,
    ];
    @observable
    permissions: { [k: string]: boolean } = {
        AIS: false,
        CBPII: false,
        PARTNER: false,
        REDEEM_TOKEN: false,
        CUSTOMIZE_FLOW: false,
        HIDE_CONSENT: false,
        ACT_ON_BEHALF_OF: false,
        REQUEST_REFUND_ACCOUNT: false,
        PERFORM_KYC: false,
        REFUND: false,
        STANDING_ORDER: false,
        FUTURE_DATED: false,
        SINGLE_IMMEDIATE_PAYMENT: false,
        VARIABLE_RECURRING_PAYMENT: false,
        BULK_TRANSFER: false,
        DO_NOT_ALLOW_API_KEY_AUTHENTICATION: false,
    };
    @observable
    displayName = '';

    @action
    updatePermissionList = () => {
        // changes as per APE-2133
        !this.isTppManager ? this.permissionsList.push(BULK_TRANSFER) : '';
    };

    @action
    updateIsAdminTppResellerFlow = (value: boolean) => {
        this.isAdminTppResellerFlow = value;
    };

    @action
    sendToAdminPage = () => {
        this.rootStore.UpdatePermissionsModalStore.closeModal();
        Object.assign(this.rootStore.page, {
            step: 1,
            type: 'ADMIN',
        });
    };

    @action
    setPermissions = (memberPermissions: { [k: string]: any }) => {
        const permissions = Object.values(memberPermissions);
        this.permissions = {
            AIS: permissions.indexOf(AIS) > -1,
            CBPII: permissions.indexOf(CBPII) > -1,
            REDEEM_TOKEN: permissions.indexOf(REDEEM_TOKEN) > -1,
            CUSTOMIZE_FLOW: permissions.indexOf(CUSTOMIZE_FLOW) > -1,
            HIDE_CONSENT: permissions.indexOf(HIDE_CONSENT) > -1,
            PARTNER: permissions.indexOf(PARTNER) > -1,
            ACT_ON_BEHALF_OF: permissions.indexOf(ACT_ON_BEHALF_OF) > -1,
            REQUEST_REFUND_ACCOUNT: permissions.indexOf(REQUEST_REFUND_ACCOUNT) > -1,
            PERFORM_KYC: permissions.indexOf(PERFORM_KYC) > -1,
            REFUND: permissions.indexOf(REFUND) > -1,
            STANDING_ORDER:
                permissions.indexOf(PIS) > -1 ? true : permissions.indexOf(STANDING_ORDER) > -1,
            FUTURE_DATED:
                permissions.indexOf(PIS) > -1 ? true : permissions.indexOf(FUTURE_DATED) > -1,
            SINGLE_IMMEDIATE_PAYMENT:
                permissions.indexOf(PIS) > -1
                    ? true
                    : permissions.indexOf(SINGLE_IMMEDIATE_PAYMENT) > -1,
            VARIABLE_RECURRING_PAYMENT: permissions.indexOf(VARIABLE_RECURRING_PAYMENT) > -1,
            BULK_TRANSFER: permissions.indexOf(BULK_TRANSFER) > -1,
            DO_NOT_ALLOW_API_KEY_AUTHENTICATION:
                permissions.indexOf(DO_NOT_ALLOW_API_KEY_AUTHENTICATION) > -1,
        };
        this.displayName = this.rootStore.displayName;
    };

    @action
    handleCheck = (event: React.ChangeEvent<any>) => {
        this.permissions[event.target.name] = event.target.checked;
    };

    @computed
    get isFilled() {
        if (
            Object.keys(this.permissions).filter((key) => this.permissions[key]).length < 1 ||
            (!this.permissions.AIS &&
                !this.permissions.STANDING_ORDER &&
                !this.permissions.SINGLE_IMMEDIATE_PAYMENT)
        ) {
            return false;
        }
        return true;
    }

    constructUrl = (email: string) => {
        const url = new URLSearchParams();
        url.append('perPage', this.perPage.toString());
        url.append('page', this.page.toString());
        url.append('email', email);
        return url.toString();
    };

    @action
    initStore = flow(
        function* (this: UpdatePermissionsModalStore, email: string, scope = TOKEN): any {
            try {
                this.updateIsAdminTppResellerFlow(true);
                const res = yield axios.get(`/api/admin/clients?${this.constructUrl(email)}`);
                const clientId = res.data.clients[0].clientId;
                this.clientId = clientId;
                const response = yield axios.get(
                    `/api/admin/clients/${encodeURIComponent(clientId)}`,
                );
                this.rootStore.member = response.data.memberDetails;
                this.rootStore.member.email = email;
                this.rootStore.member.scope = scope;
                this.setPermissions(this.rootStore.member.permissions);
                this.displayName = res.data.clients[0].companyName;

                // this is for Create Super Admin flow.
                if (!this.displayName) {
                    let user = yield axios.get(
                        `/api/user?email=${encodeURIComponent(email)}&scope=${scope}`,
                    );
                    user = user.data;
                    this.displayName = user.firstName + ' ' + user.lastName;
                }
                this.rootStore.displayName = this.displayName;
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );

    @action
    handleSave = flow(
        function* (this: UpdatePermissionsModalStore, AppStore: AppStore) {
            try {
                const permissions = Object.keys(this.permissions).filter(
                    (key) => this.permissions[key],
                );
                const route = this.isTppManager ? 'member' : 'admin';
                yield axios.put(
                    `/api/${route}/member-permissions/${encodeURIComponent(this.clientId)}`,
                    {
                        permissions,
                    },
                );

                this.closeModal();

                if (this.isAdminTppResellerFlow) {
                    this.rootStore.setCurrentUserPermissions(permissions);
                    this.rootStore.sendToAddConfigurations();
                } else {
                    this.rootStore.handleFetch(AppStore);
                }
            } catch (e) {
                console.error(e);
                AppStore.errorStore.openErrorModal(e);
            }
        }.bind(this),
    );
}

export default UpdatePermissionsModalStore;
