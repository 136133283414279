import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
//types
import { ILinkWrapper as Props } from '../types';

const styles = () => ({
    root: {
        color: 'var(--primary)',
        textDecoration: 'none',
    },
});

const LinkWrapper: React.FC<Props> = ({ classes, className = '', children, href, ...props }) => {
    return href === '#' || href.indexOf('http') === 0 ? (
        <a href={href} className={clsx(classes.root, className)} {...props}>
            {children}
        </a>
    ) : (
        <Link href={href} className={clsx(classes.root, className)} {...props}>
            {children}
        </Link>
    );
};

export default withStyles(styles, { name: 'LinkWrapper' })(LinkWrapper);
