import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import tokenLogo from '../../../images/token-logo.svg';
import { ILogoWrapper } from '../types';

const defaultLogo = <img src={tokenLogo} />;

const styles = () => ({
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    mainLogo: {
        height: '30px',
    },
    altLogo: {
        marginLeft: '30px',
        height: '30px',
    },
});

const LogoWrapper: React.FC<ILogoWrapper> = ({
    partnerlogo,
    hideToken = false,
    classes,
    className = '',
    ...props
}) => {
    if (partnerlogo) {
        return (
            <div className={clsx(classes.logoContainer)}>
                {React.cloneElement(partnerlogo, {
                    className: clsx(classes.mainLogo, className),
                    key: 'partnerlogo',
                    ...props,
                })}
                {!hideToken &&
                    React.cloneElement(defaultLogo, {
                        className: clsx(classes.altLogo, className),
                        key: 'defaultLogo',
                        ...props,
                    })}
            </div>
        );
    }

    return (
        <>
            {React.cloneElement(defaultLogo, {
                className: clsx(classes.mainLogo, className),
                key: 'defaultLogo',
                ...props,
            })}
        </>
    );
};

export default withStyles(styles, { name: 'LogoWrapper' })(LogoWrapper);
