module.exports = {
    companyInfoParams: [
        'country',
        'companyName',
        'companyEmail',
        'companyLegalName',
        'vat',
        'incorporationType',
        'crn',
        'address',
        'address2',
        'city',
        'state',
        'zip',
        'complianceContactEmail',
        'complianceContactPhone',
    ],
};
