import { getCountry } from '@jbreneman/country-state-list';
import { Grid, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import React from 'react';

import ModalStore from 'components/common/modals/ModalStore';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';

// constants
import { companyInfoParams } from 'constants/companyInfoForm';
import { countries } from 'constants/countries';
import {
    STANDING_ORDER,
    SINGLE_IMMEDIATE_PAYMENT,
    FUTURE_DATED,
} from 'constants/memberPermissions';
import { AIS, PIS } from 'constants/permissions';
import { provinces } from 'constants/province';

// helpers
import isValidAlias from 'helpers/is-valid-alias/isValidAlias';
import isValidEmail from 'helpers/is-valid-email/isValidEmail';

const { publicRuntimeConfig } = require('next/config').default();
const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class CompanyInfoDetailsStore {
    constructor(rootStore, disabled, isTppCompanyDetails = false, isEdit = false) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.TppSuccessStore = new ModalStore();
        this.disabled = disabled;
        this.isTppCompanyDetails = isTppCompanyDetails;
        this.isEdit = isEdit;
    }

    zipLabel = 'postalCode';
    stateLabel = 'province';

    @observable
    form = {
        country: '',
        companyName: '',
        companyWebsite: '',
        companyAlias: '',
        companyEmail: '',
        companyLegalName: '',
        vat: '',
        incorporationType: '',
        crn: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        complianceContactEmail: '',
        complianceContactPhone: '',
    };
    @observable
    companyInfo = {};
    @observable
    loading = false;
    @observable
    states = [];
    @observable
    data = {};
    @observable
    disabled;
    @observable
    aliasEdit = true;
    @observable
    hidePermissionsForm = false;
    @observable
    aliasValue = '';
    @observable
    appType = {
        [AIS]: false,
        [PIS]: false,
        [STANDING_ORDER]: false,
        [SINGLE_IMMEDIATE_PAYMENT]: false,
        [FUTURE_DATED]: false,
    };
    @observable
    memberType = null;
    @observable
    resellerType = {
        name: '',
        memberId: '',
    };
    @observable
    disableStep = false;
    @observable
    openSnackbar = false;
    @observable
    resellerError = false;
    @observable
    snackbarKeys = {
        info: '',
        error: '',
    };
    @observable
    resellerTypes = [];

    @computed
    get isCountryFilled() {
        return this.form.country && true;
    }

    @action
    updateSnackbarKeys = (type, key) => {
        this.snackbarKeys[type] = key;
    };

    @action
    setErrorState = (isError) => {
        this.resellerError = isError;
    };

    @action
    setSnackbarState = (isOpen) => {
        this.openSnackbar = isOpen;
    };

    @action
    setDisableStep = (disableStep) => {
        this.disableStep = disableStep;
    };

    @action
    fetchResellersWithRealmId = flow(
        function* () {
            this.setSnackbarState(true);
            try {
                const res = yield axios.get('/api/admin/resellers');
                this.resellerTypes = res?.data?.resellers || [];
                this.setSnackbarState(false);
            } catch (e) {
                console.error(e);
                this.setErrorState(true);
            }
        }.bind(this),
    );

    @action
    handleResellerTypeChange = flow(
        function* (event, value) {
            event.preventDefault();
            if (value) {
                this.resellerType = value;
                yield this.rootStore.loadResellerDetails(this.resellerType.memberId);
            } else {
                this.resellerType = {
                    name: '',
                    memberId: '',
                };
                this.setDisableStep(true);
            }
        }.bind(this),
    );

    @action
    handleChecked = (event) => {
        this.appType[event.target.name] = event.target.checked;

        // Triggers an update by re-setting the object
        this.appType = Object.assign({}, this.appType);
    };

    @action
    handleMemberTypeChange = (e) => {
        this.memberType = e.target.value;
    };

    @action
    handleChange = (e) => {
        this.form[e.target.name] = e.target.value;
    };

    @action
    handleCompanyAliasChange = (e) => {
        this.aliasValue = e.target.value;
        this.form.companyAlias = e.target.value;
    };

    @action
    disableAliasEdit = (bool) => {
        this.aliasEdit = bool;
    };

    @computed
    get isFormFilled() {
        let isFilled = true;

        if (this.isTppCompanyDetails) {
            if (!this.isEdit) {
                // must be in domain format
                if (this.form.companyAlias && !isValidAlias(this.form.companyAlias)) {
                    isFilled = false;
                }

                if (!Object.values(this.appType).reduce((prev, curr) => prev || curr, false)) {
                    isFilled = false;
                }
            }

            if (!this.form.companyName || !this.form.companyAlias) {
                isFilled = false;
            }
        }

        if (!this.isTppCompanyDetails) {
            if (!this.form.companyWebsite) {
                isFilled = false;
            }
        }

        // If email is filled out, it must be in proper format
        if (this.form.companyEmail && !isValidEmail(this.form.companyEmail)) {
            isFilled = false;
        }

        return isFilled;
    }

    @action
    updateCountryField = (e) => {
        if (e.target.value === 'FR' || e.target.value === 'HU') {
            this.states = provinces[e.target.value];
        } else {
            const { states } = getCountry(e.target.value);
            this.states = states;
        }
        this.form[e.target.name] = e.target.value;
    };

    @action
    handleSinglePermissions = (user) => {
        const filteredPermissions = user.permissions.filter(
            (permission) => permission === AIS || permission === PIS,
        );
        if (filteredPermissions.length === 1) {
            this.hidePermissionsForm = true;
            this.appType[filteredPermissions[0]] = true;
        }
    };

    @action
    handleFetch = flow(
        function* (saveOnlyAsReference) {
            this.loading = true;
            try {
                const data = yield axios.get('/api/client/companyDetails');
                if (data.data.companyDetails) {
                    const res = data.data.companyDetails;
                    if (res.country) {
                        this.states = getCountry(res.country).states;
                    }

                    if (!saveOnlyAsReference) {
                        this.form = {
                            ...this.form,
                            ...res,
                        };
                    }

                    this.companyInfo = {
                        ...res,
                    };
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleSubmit = flow(
        function* (AppStore, onSuccess = Function.prototype) {
            this.loading = true;

            try {
                const companyDetails = {};
                let permissions;
                companyInfoParams.forEach((param) => {
                    if (this.form[param]) {
                        companyDetails[param] = this.form[param];
                    }
                });

                // NOTE(Jules) this.isTppCompanyDetails === true for reseller experience
                if (this.isTppCompanyDetails) {
                    companyDetails.companyAlias = this.aliasValue;
                    permissions = Object.keys(this.appType).filter((key) => this.appType[key]);
                } else {
                    companyDetails.companyWebsite = this.form.companyWebsite;
                }

                let res;
                if (this.isTppCompanyDetails) {
                    res = yield axios.post('/api/clients/companyDetails', {
                        companyDetails,
                        permissions,
                        memberType: this.memberType,
                        parentEmail: this.rootStore.resellerEmail,
                        scope: this.rootStore.resellerScope,
                        parentMemberId: this.rootStore.resellerMemberId,
                    });
                } else {
                    res = yield axios.post('/api/client/companyDetails', {
                        companyDetails,
                        permissions,
                    });
                }

                if (this.isTppCompanyDetails) {
                    this.rootStore.clientId = res.data.clientId;
                    this.rootStore.CreateTppStore.companyName = companyDetails.companyName;
                    this.TppSuccessStore.openModal();
                } else {
                    this.GenericSuccessStore.openModal();
                }
                yield onSuccess();
                this.disableAliasEdit(true);
                this.clearForm();
            } catch (e) {
                console.error(e);
                AppStore.errorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    clearForm = () => {
        this.form = {
            country: '',
            companyName: '',
            companyWebsite: '',
            companyAlias: '',
            companyEmail: '',
            companyLegalName: '',
            vat: '',
            incorporationType: '',
            crn: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            complianceContactEmail: '',
            complianceContactPhone: '',
        };
        this.aliasValue = '';
        this.hidePermissionsForm = false;
        this.appType = {
            [AIS]: false,
            [PIS]: false,
            [STANDING_ORDER]: false,
            [SINGLE_IMMEDIATE_PAYMENT]: false,
            [FUTURE_DATED]: false,
        };
        this.resellerType = {
            name: '',
            memberId: '',
        };
    };

    renderAddressForm = (t) => {
        const { form, handleChange, isCountryFilled, stateLabel, zipLabel, states } = this;

        return (
            <>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="address"
                        type="text"
                        label={t('address')}
                        value={form.address}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="address2"
                        type="text"
                        label={t('address2')}
                        value={form.address2}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="city"
                        type="text"
                        label={t('city')}
                        value={form.city}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectWrapper
                        name="state"
                        label={t(stateLabel)}
                        value={form.state}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}>
                        {states.map((s) => (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={3}>
                    <TextFieldWrapper
                        name="zip"
                        type="text"
                        label={t(zipLabel)}
                        value={form.zip}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
            </>
        );
    };

    renderCompanyForm = (t) => {
        const {
            form,
            handleChange,
            isCountryFilled,
            updateCountryField,
            handleCompanyAliasChange,
        } = this;
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="country"
                        label={t('country')}
                        value={form.country}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 600,
                                    },
                                },
                            },
                        }}
                        onChange={updateCountryField}
                        disabled={this.disabled}
                        required>
                        {countries.map((c) => (
                            <MenuItem key={c.code} value={c.code}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="companyName"
                        type="text"
                        label={t('companyName')}
                        value={form.companyName}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    {this.isTppCompanyDetails ? (
                        <TextFieldWrapper
                            name={'companyAlias'}
                            type="text"
                            label={t('companyAlias')}
                            value={form.companyAlias}
                            onChange={handleCompanyAliasChange}
                            disabled={
                                (!this.aliasEdit && publicRuntimeConfig.tokenEnv !== 'dev') ||
                                !isCountryFilled ||
                                this.disabled
                            }
                            required={this.isTppCompanyDetails}
                        />
                    ) : (
                        <TextFieldWrapper
                            name={'companyWebsite'}
                            type="text"
                            label={t('companyWebsite')}
                            value={form.companyWebsite}
                            onChange={handleChange}
                            disabled={!isCountryFilled || this.disabled}
                            required={!this.isTppCompanyDetails}
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="companyEmail"
                        type="email"
                        label={t('companyEmail')}
                        value={form.companyEmail}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                        required={this.isTppCompanyDetails}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="companyLegalName"
                        type="text"
                        label={t('companyLegalName')}
                        value={form.companyLegalName}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="vat"
                        type="text"
                        label={t('vat')}
                        value={form.vat}
                        onChange={handleChange}
                        tooltipText={t('common:tooltips.vat')}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="incorporationType"
                        type="text"
                        label={t('incorporationType')}
                        value={form.incorporationType}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="crn"
                        type="text"
                        label={t('crn')}
                        value={form.crn}
                        onChange={handleChange}
                        tooltipText={t('common:tooltips.companyNumber')}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="complianceContactEmail"
                        type="email"
                        label={t('complianceContactEmail')}
                        value={form.complianceContactEmail}
                        onChange={handleChange}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="complianceContactPhone"
                        type="text"
                        label={t('complianceContactPhone')}
                        value={form.complianceContactPhone}
                        onChange={handleChange}
                        maxLength={10}
                        disabled={!isCountryFilled || this.disabled}
                    />
                </Grid>
                <Grid item xs={6} />
                {this.renderAddressForm(t)}
            </Grid>
        );
    };
}

export default CompanyInfoDetailsStore;
