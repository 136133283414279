import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import isValidEmail from 'helpers/is-valid-email/isValidEmail';
import { OFF, ON } from 'constants/inputField';
import { SIGNUP_ALREADY_EXISTS } from 'constants/errors';
import { ADMIN } from 'constants/roles';
import ModalStore from '../../common/modals/ModalStore';
import { UNDER_RESELLER } from 'constants/clientType';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class CreateTppStore {
    constructor(rootStore, isAdmin = false) {
        this.rootStore = rootStore;
        this.CompleteModalStore = new ModalStore();
        this.isAdmin = isAdmin;
    }

    @observable
    form = {
        firstName: '',
        lastName: '',
        email: '',
    };
    @observable
    loading = false;
    @observable
    companyName = '';
    @observable
    memberPermissions = [];
    @observable
    memberType = '';

    validateForm = (form) => {
        const { firstName, lastName, email } = form;
        const hasFirstName = firstName ? ON : OFF,
            hasLastName = lastName ? ON : OFF,
            hasEmail = email ? ON : OFF;
        return {
            hasFirstName,
            hasLastName,
            hasEmail,
        };
    };

    @computed
    get isFormFilled() {
        const validateResult = this.validateForm(this.form);
        const { hasFirstName, hasLastName, hasEmail } = validateResult;

        return (
            hasEmail === ON &&
            hasFirstName === ON &&
            hasLastName === ON &&
            isValidEmail(this.form.email)
        );
    }

    @action
    handleChange = (event) => {
        this.form[event.target.name] = event.target.value;
    };

    @action
    handleSubmit = flow(
        function* (AppStore) {
            this.loading = true;
            try {
                const parentMemberId = this.rootStore.resellerMemberId;
                const parentEmail = this.rootStore.resellerEmail;
                const scope = this.rootStore.resellerScope;

                yield axios.post(`/api/clients/users/${this.rootStore.clientId}`, {
                    ...this.form,
                    role: ADMIN,
                    parentEmail,
                    scope,
                    parentMemberId,
                });

                // set company name as profile name for this client ID
                let url = `/api/clients/${encodeURIComponent(this.rootStore.clientId)}`;
                if (parentMemberId) {
                    url = `${url}?parentMemberId=${encodeURIComponent(
                        parentMemberId,
                    )}&parentEmail=${encodeURIComponent(parentEmail)}&scope=${scope}`;
                }
                const clientDetails = yield axios.get(url);
                this.memberType = clientDetails.data.memberType;
                const tppMemberId = clientDetails.data.tppMemberId;
                if (tppMemberId) {
                    yield axios.post(`/api/member/${tppMemberId}/profile`, {
                        profileName: this.companyName,
                        initial: true,
                        parentEmail,
                        scope,
                        parentMemberId,
                    });
                }
                if (this.isAdmin) {
                    yield this.rootStore.setResellerDetails();
                    this.rootStore.member.email = this.form.email;
                    this.rootStore.member.scope = scope;
                    this.rootStore.displayName = this.companyName;
                }
                this.CompleteModalStore.openModal();
                this.clearForm();
            } catch (e) {
                console.error(e);

                const errorCode =
                    e?.response?.data?.code === 'auth/email-already-in-use' &&
                    SIGNUP_ALREADY_EXISTS;
                AppStore.errorStore.openErrorModal(e, errorCode);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    sentToEditPermissionsModal = async () => {
        this.rootStore.clientType = UNDER_RESELLER;
        this.rootStore.UpdatePermissionsModalStore.clientType = UNDER_RESELLER;
        this.rootStore.UpdatePermissionsModalStore.memberType = this.memberType;
        await this.rootStore.UpdatePermissionsModalStore.initStore(
            this.rootStore.member.email,
            this.rootStore.member.scope,
        );
        this.rootStore.UpdatePermissionsModalStore.openModal();
    };

    @action
    clearForm = () => {
        this.form = {
            firstName: '',
            lastName: '',
            email: '',
        };
    };
}

export default CreateTppStore;
