import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
//types
import { IDialogWrapperProps } from '../types';

const styles = () => ({
    root: {
        '& .shadow': {
            position: 'sticky',
            bottom: 0,
            left: 0,
            width: '100%',
            textAlign: 'center',
            margin: 0,
            padding: '10px 0',
            backgroundImage: 'linear-gradient(to bottom, transparent, white)',
        },
    },
});

const DialogWrapper: React.FC<IDialogWrapperProps> = ({
    hideDivider,
    open,
    onClose,
    title,
    disableEscapeKeyDown = false,
    disableBackdropClick = false,
    children,
    classes,
    className,
    maxWidth,
}) => {
    return (
        <Dialog
            disableEscapeKeyDown={disableEscapeKeyDown}
            disableBackdropClick={disableBackdropClick}
            open={!!open}
            onClose={onClose}
            fullWidth={!!maxWidth}
            maxWidth={maxWidth}
            className={clsx(classes.root, className)}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {children.slice(0, children.length - 1)}
                {!hideDivider && <div className="shadow" />}
                {!hideDivider && <Divider />}
            </DialogContent>
            <DialogActions>{children.slice(-1)}</DialogActions>
        </Dialog>
    );
};

export default withStyles(styles, { name: 'DialogWrapper' })(DialogWrapper);
